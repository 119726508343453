import { useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';


var options = {
    'damping' : 0.05,
    'continuousScrolling' : true,
    'delegateTo': document.body,
    'renderByPixels':true,
}
const SmoothScroll = () => {
useEffect(() => {
    Scrollbar.init(document.body,options);


}, []);

return null;
};

export default SmoothScroll;
