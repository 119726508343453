import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import UpperNav from './components/UpperNav';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Services from './components/Services';
import KnowUs from './components/KnowUs';
import Ad from './components/Ad';
import ServiceText from './components/ServiceText';
import Benefits from './components/Benefits';
import Numbers from './components/Numbers';
import Contact from './components/Contact';
import Footer from './components/Footer';

//pages 

import AboutUsPage from './components/AboutUsPage';
import SolutionsPage from './components/SolutionsPage';
import ContactPage from './components/ContactPage';
import CareersPage from './components/CareersPage';
import GetProposal from './components/GetProposal';
import SmoothScroll from './components/SmoothScroll';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {

return (
    <div className="App">

            <Navbar />

            <Routes>
            

            <Route path="/" element={
                    <>
                        <Landing />
                        <KnowUs />
                        <Ad text="Light your business's way to success with Aurora Marketing Group's dynamic services." />

                        <Services />
                        <ServiceText />
                        <Ad text="Set your business on the path to sucess with Aurora Marketing Group's prompt solutions."/>
                        <Benefits />
                        <Numbers />
                        <AboutUsPage />
                        <ScrollToTopButton />

                        <Contact />




                        
                    </>
                } />



                
            <Route path="/contact-us" element={
                    <>
                        <ContactPage />
                        <ScrollToTopButton />

                    </>
                } />

            <Route path="/careers" element={
                    <>
                        <CareersPage />
                        <ScrollToTopButton />

                    </>
                } />


            <Route path="/get-proposal" element={
                    <>
                        <GetProposal />
                        <ScrollToTopButton />

                    </>
                } />

        </Routes>

        <Footer />

        
    </div>
);
}

export default App;
