import './css/uppernav.css';

function UpperNav(){
    return(
        <>

            <div className='upper-nav'>
                <div className='container-fluid'>

                    <div className='row'>

                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='left'>

                            <a  aria-label="email" href="mailto:info@aurora-ksa.com" target="_blank">
                                <i class="las la-envelope"></i>
                                info@aurora-ksa.com
                            </a>

                            <a aria-label="address" target='_blank' href="https://maps.app.goo.gl/Z2taj2ULwqfrYfRZ6?g_st=ic">
                                <i class="las la-map-marker-alt"></i>
                                jeddah 
                            </a>


                        </div>
                    </div>


                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='right'>
                        <a aria-label='facebook aurora link' target="_blank" href="https://www.facebook.com/profile.php?id=61551042727391&mibextid=2JQ9oc">
                            <i class="lab la-facebook-f"></i>
                        </a>

                        <a aria-label='instagram aurora link' target="_blank" href="https://instagram.com/aurora.marketing.group?igshid=OGQ5ZDc2ODk2ZA==">
                            <i class="lab la-instagram"></i>
                        </a>

                        <a aria-label='twitter aurora link' target="_blank" href="https://x.com/auroraksa23?s=11&t=Z3dWoTxy2YbeXIZpiESFWg">
                            <i class="lab la-twitter"></i>
                        </a>

                        <a aria-label='linked-in aurora link' target="_blank" href="https://www.linkedin.com/company/aurora-marketing-group/">
                            <i class="lab la-linkedin-in"></i>
                        </a>
                        </div>
                    </div>



                    </div>
                </div>
            </div>
        
        </>
    )
}

export default UpperNav;