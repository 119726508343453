import React, { useState } from 'react';
import './css/contact.css';
import ContactImg from './images/imagestouse/6.webp';
import axios from '../config/index'; // Make sure this import is correct

function Contact() {
  // Define the initial form state
  const initialFormData = {
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/send-email', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Successful submission, you can display a success message or redirect the user
        alert('Message sent successfully!');
        // Reset the form
        resetForm();
      } else {
        // Handle server error or validation errors
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      // Handle network error or other errors
      console.error('Error:', error);
      alert('An error occurred while sending the message.');
    }
  };


return (
    <section className="contact">
    <div className="container-fluid">
        <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="contact-images">
            <div className="back-lay"></div>
            <img loading="lazy" src={ContactImg} alt="ContactUs" />
            <div className="contact-numbers">

                <div className="number">
                <h2>10 +</h2>
                <h3>Services Features</h3>
                </div>
                <div className="number">
                <h2>12 +</h2>
                <h3>Years of Experience</h3>
                </div>
            </div>
            </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="contact-form">
            <h1>
                Get <span>In</span> Touch
            </h1>
            <form onSubmit={handleSubmit}>
                <div className="inputs">
                <div className="input">
                    <input
                    type="text"
                    name="name"
                    placeholder="Enter your name *"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <input
                    type="email"
                    name="email"
                    placeholder="Enter your Email *"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    />
                </div>
                </div>
                <div className="inputs">
                <div className="input">
                    <input
                    type="text"
                    name="subject"
                    placeholder="Enter your Subject *"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <input
                    type="tel"
                    name="phone"
                    placeholder="Enter your Phone *"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    />
                </div>
                </div>
                <div className="inputs">
                <div className="input">
                    <textarea
                    name="message"
                    placeholder="Type Your Message *"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    ></textarea>
                </div>
                </div>
                <button aria-label="Send Message Form" id="submit-button" type="submit">
                Send Message Now <i className="las la-arrow-right"></i>
                </button>
            </form>
            </div>
        </div>
        </div>
    </div>
    </section>
);
}

export default Contact;
