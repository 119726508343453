import React, { useState } from 'react';
import axios from '../config/index'; // Make sure this import is correct
import './css/contact.css';
import CareerImg from './images/imagestouse/4.webp';

function Contact() {
  // Define the initial form state
  const initialFormData = {
    name: '',
    email: '',
    position: '',
    phone: '',
    education: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/send-application', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Successful submission, you can display a success message or redirect the user
        alert('Message sent successfully!');
        // Reset the form
        resetForm();
      } else {
        // Handle server error or validation errors
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      // Handle network error or other errors
      console.error('Error:', error);
      alert('An error occurred while sending the message.');
    }
  };

  return (
    <section className="contact">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="contact-images">
              <div className="back-lay"></div>
              <img loading="lazy" src={CareerImg} alt="ContactUs" />
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="contact-form">
              <h1>
                Join <span>Aurora</span> Team
              </h1>

              <p>
                Join Aurora Team supporting businesses grow and shine. Everyday we strive to reach more businesses in more places with innovative marketing technologies & strategies.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="inputs">
                  <div className="input">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter your name *"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email *"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="inputs">
                  <div className="input">
                    <input
                      type="text"
                      name="position"
                      placeholder="Preferred Position  *"
                      value={formData.position}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Enter your Phone *"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="inputs">
                  <div className="input">
                    <textarea
                      name="education"
                      placeholder="Education background *"
                      value={formData.education}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>
                <button aria-label="Send Message Form" id="submit-button" type="submit">
                  Send Application <i className="las la-arrow-right"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
