import './css/navbar.css';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef  } from "react";
import $ from 'jquery';


function Navbar(){

    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $("nav").addClass("active");

            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    


        // Scroll to the "experience" section when the anchor link is clicked
        const scrollToExperience = (element) => {
            const experienceSection = document.getElementById("experience");
        
            if (experienceSection) {
                experienceSection.scrollIntoView({ behavior: "smooth" });
            } else {
                // If the element is not found, create a MutationObserver to watch for changes.
                const observer = new MutationObserver((mutationsList, observer) => {
                    // Check if the "experience" element has been added to the DOM.
                    const updatedExperienceSection = document.getElementById("experience");
                    if (updatedExperienceSection) {
                        // Once it's found, scroll to it and disconnect the observer.
                        updatedExperienceSection.scrollIntoView({ behavior: "smooth" });
                        observer.disconnect();
                    }
                });
        
                // Start observing the document for changes in the DOM.
                observer.observe(document, { childList: true, subtree: true });
            }
        };




        const scrollToAboutUs = (element) => {
            const experienceSection = document.getElementById("about-us");
        
            if (experienceSection) {
                experienceSection.scrollIntoView({ behavior: "smooth" });
            } else {
                // If the element is not found, create a MutationObserver to watch for changes.
                const observer = new MutationObserver((mutationsList, observer) => {
                    // Check if the "experience" element has been added to the DOM.
                    const updatedExperienceSection = document.getElementById("about-us");
                    if (updatedExperienceSection) {
                        // Once it's found, scroll to it and disconnect the observer.
                        updatedExperienceSection.scrollIntoView({ behavior: "smooth" });
                        observer.disconnect();
                    }
                });
        
                // Start observing the document for changes in the DOM.
                observer.observe(document, { childList: true, subtree: true });
            }
        };


        
        const scrollToServices = (element) => {
            const experienceSection = document.getElementById("our-solutions");
        
            if (experienceSection) {
                experienceSection.scrollIntoView({ behavior: "smooth" });
            } else {
                // If the element is not found, create a MutationObserver to watch for changes.
                const observer = new MutationObserver((mutationsList, observer) => {
                    // Check if the "experience" element has been added to the DOM.
                    const updatedExperienceSection = document.getElementById("our-solutions");
                    if (updatedExperienceSection) {
                        // Once it's found, scroll to it and disconnect the observer.
                        updatedExperienceSection.scrollIntoView({ behavior: "smooth" });
                        observer.disconnect();
                    }
                });
        
                // Start observing the document for changes in the DOM.
                observer.observe(document, { childList: true, subtree: true });
            }
        };


        





    return(
        <>
            <nav>
                <div className='container-fluid'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="logo" />
                        </div>

                        
                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-elements">
                        <button id="close-nav"> <i className="las la-times"></i> </button>

                            <ul>
                                <li>
                                    <RouterLink aria-label='home page link' to="/">
                                        HOME 
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink onClick={scrollToAboutUs}  aria-label='about us link'  to="/#about-us">
                                        ABOUT US
                                    </RouterLink>
                                </li>
                                


                                <li>
                                <RouterLink onClick={scrollToServices} aria-label='our solutions link' to="/#our-solutions">
                                        SERVICES
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink onClick={scrollToExperience} aria-label='contact us link' to="/#experience">
                                        EXPERIENCE
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink aria-label='contact us link' to="/careers">
                                        CAREERS
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink aria-label='contact us link' to="/get-proposal">
                                        GET A PROPOSAL
                                    </RouterLink>
                                </li>



                                <li>
                                    <RouterLink aria-label='contact us link' to="/contact-us">
                                        CONTACT US
                                    </RouterLink>
                                </li>

                                
                            

                                
                            </ul>


                            <div className='phone'>
                                <i class="las la-phone-volume"></i>
                                <a href="tel:+9660543708205 " target="_blank">
                                    <h6> Give Us A Call </h6>
                                    <a aria-label='phonenumber aurora' href="tel:+9660543708205">
                                        +9660543708205
                                    </a>
                                </a>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;