import './css/knowus.css';

import BigImage from './images/imagestouse/2.webp';
import SmallImg from './images/imagestouse/6.webp';


function KnowUs(){
    return(
        <>
            <section id="about-us" className='knowus'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='knowus-images'>


                                <div className='experience'>
                                    <i class="las la-bullhorn"></i>
                                    <div>
                                        <h1> 12+ </h1>
                                        <h2> Work experience </h2>
                                    </div>
                                </div>

                                <div className='circle'></div>

                                <img alt="Big aurora Img" src={BigImage} className='big-img' />

                                <img alt="Small aurora Img" src={SmallImg} className='small-img' />

                            </div>
                        </div>



                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='knowus-text'>

                                <h2> Know Us More </h2>
                                <h1> <span></span> Why Choose Aurora Marketing Agency? </h1>

                                {/* <p> At Aurora Marketing Agency, we go above and beyond to exceed our clients'
                                expectations. Whether you're a startup, small or medium-sized enterprise, or a
                                large corporation, we possess the expertise and exp </p> */}

                                <div className='text'>
                                    <i class="las la-user-check"></i>
                                    <div className='info'>
                                        <h3> 	INFLUENCERS </h3>
                                        <p> We are guardians, thinkers, activists and creative souls 
                                        who all believe in the power and implications of every 
                                        message a brand sends.
                                        </p>
                                    </div>
                                </div>

                                <div className='text'>
                                    <i class="las la-globe-europe"></i>
                                    <div className='info'>
                                        <h3> FIERCLY </h3>
                                        <p> We are free to do the best work possible while not 
                                            having to answer to any global communications 
                                            network. Our work is guided by our clients’ objectives 
                                            and their audiences’ needs and aspirations.
                                            </p>
                                    </div>
                                </div>



                                <div className='text'>
                                    <i class="las la-clipboard-check"></i>
                                    <div className='info'>
                                        <h3> SIMPLE, enjoyable, memorable. </h3>
                                        <p> That's the right approach to creating any successful creative communications strategy. </p>
                                    </div>
                                </div>



                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default KnowUs;