import './css/numbers.css';

import img1 from './images/numbers/1_0.webp';
import img2 from './images/numbers/2_1.webp';
import img3 from './images/numbers/3_2.webp';
import img4 from './images/numbers/4_3.webp';



function Numbers(){
    return(
        <>
            <section className='numbers'>
                <div className='container-fluid'>
                    <div className='header'>
                        <h2> OUR NUMBERS </h2>
                        <h1> <span></span> EXPLORE OUR NUMBERS  </h1>
                    </div>

                    {/* <div className='row'>


                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='service-number'>
                                <img src={img1} alt='service number img' />
                                <div className='text'>
                                    <h3> INFLUENCERS </h3>
                                    <h1> of consumer behavior </h1>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='service-number'>
                            <img src={img2} alt='service number img' />
                                <div className='text'>
                                    <h3> FIERCLY </h3>
                                    <h1>independent  </h1>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='service-number'>
                            <img src={img3} alt='service number img' />
                                <div className='text'>
                                    <h3> SIMPLE, enjoyable, memorable </h3>
                                    <h1> That's the right approach   </h1>
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='service-number'>
                            <img src={img4} alt='service number img' />
                                <div className='text'>
                                    <h3> Services that Propel Your Business </h3>
                                    <h1> Unleash the Potential  </h1>
                                </div>
                            </div>
                        </div>

                    </div> */}
                </div>

                <div className="numbers-content">
                    <div className='content'>
                        <div className='number'>
                            <i class="las la-list-alt"></i>
                            <h4> 100+ </h4>
                            <h5> Projects Completed </h5>
                        </div>

                        <div className='number'>
                            <i class="las la-redo-alt"></i>
                            <h4> 10+ </h4>
                            <h5> Service Features </h5>
                        </div>


                        <div className='number'>
                            <i class="las la-user-tie"></i>

                            <h4> 12+ </h4>
                            <h5> Years Of Experience </h5>
                        </div>

                        <div className='number'>
                            <i class="las la-users"></i>
                            <h4> 50+ </h4>
                            <h5> Team Members </h5>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Numbers;