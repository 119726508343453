import './css/services.css';

import ServiceImg1 from './images/services/branding.webp';
import ServiceImg2 from './images/services/advertising.webp';
import ServiceImg3 from './images/services/scoialmedia.webp';
import ServiceImg4 from './images/services/website.webp';
import ServiceImg5 from './images/services/visualization.webp';
import ServiceImg6 from './images/services/packaging.webp';
import ServiceImg7 from './images/services/activation.webp';
import ServiceImg8 from './images/services/digitalmarketing.webp';

import ServiceTextBack from './images/serviceback.webp';

import React, { useState,useEffect } from 'react';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';




function Services(){
    return(
        <>
            <section  id="our-solutions"  className='services'>
                    <div className='service-text-main'>

                        <img src={ServiceTextBack} alt="service dots" />


                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='service-text'>
                                    <h2> OUR SOLUTIONS </h2>
                                    <h1><span></span> Services That Meet Your Unique Needs.
                                    </h1>
                                </div>
                            </div>


                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='service-text'>
                                    <p> At Aurora Marketing Agency, we are fueled by our values and culture of being at the forefront of
                                        innovative and effective marketing solutions in Jeddah.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>



                    <div className='row'>

                        <div className="services-slider">

                        <div className="buttons">
                            <button aria-label="left slide button" className='prev-btn'><i class="las la-arrow-left"></i></button>
                            <button aria-label="right slide button" className='next-btn'><i class="las la-arrow-right"></i></button>
                        </div>
                    
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                    nextEl: '.next-btn',
                    prevEl: '.prev-btn',
                    }} pagination={true}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay,Navigation,Pagination]}
                        > 

                        <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg1} alt="BRAND" />
                                <i class="las la-chart-pie"></i>
                                <h1> BRANDING</h1>
                            </div>

                            </SwiperSlide>

                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg2} alt="ADVERTISING" />
                                <i class="las la-pager"></i>
                                <h1> ADVERTISING </h1>
                            </div>
                            </SwiperSlide>

                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg3} alt="SOCIAL MEDIA" />
                                <i class="las la-pen-nib"></i>
                                <h1> SOCIAL MEDIA </h1>
                            </div>
                            </SwiperSlide>


                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg4} alt="WEBSITE & DIGITAL" />
                                <i class="las la-pen-nib"></i>
                                <h1> WEBSITE & DIGITAL </h1>
                            </div>
                            </SwiperSlide>


                            
                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg5} alt="VISUALIZATION" />
                                <i class="las la-pen-nib"></i>
                                <h1> VISUALIZATION </h1>
                            </div>
                            </SwiperSlide>


                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg6} alt="PACKAGING" />
                                <i class="las la-pen-nib"></i>
                                <h1> PACKAGING </h1>
                            </div>
                            </SwiperSlide>

                            
                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg7} alt="ACTIVATION" />
                                <i class="las la-pen-nib"></i>
                                <h1> ACTIVATION </h1>
                            </div>
                            </SwiperSlide>


                                
                            <SwiperSlide>

                            <div className='service'>
                                <img src={ServiceImg8} alt="DIGITAL MARKETING " />
                                <i class="las la-pen-nib"></i>
                                <h1> DIGITAL MARKETING </h1>
                            </div>
                            </SwiperSlide>

                        </Swiper>

                        </div>

                    </div>
            </section>
        </>
    )
}

export default Services;