import { useEffect,useState  } from 'react';
import './css/servicetext.css';
import './css/servicesm.css';


import ServiceTextIMg from './images/servicetext.webp';

function ServiceText(){


    const [activeDescriptions, setActiveDescriptions] = useState([]);

    const handleDescriptionClick = (index) => {
        if (activeDescriptions.includes(index)) {
            // If the description is already active, remove it from the activeDescriptions array
            setActiveDescriptions(activeDescriptions.filter((item) => item !== index));
        } else {
            // If the description is not active, add it to the activeDescriptions array
            setActiveDescriptions([...activeDescriptions, index]);
        }
        };

        const descriptions = [
            {
                header: 'BRANDING',
                title: '<strong>BRAND</strong> strategy and identity.',
                para1: "Your brand is not what you say it is; your brand is what others say it is when you are not in the room!",
                para2: "It's not just logo design;",
                para: "it's a methodology that starts with understanding the vision, then developing a solid brand strategy, telling a brand story, and creating a representative visual identity."
            },
            {
                header: 'ADVERTISING',
                title: '<strong>ADVERTISING</strong> messaging and design.',
                para1: "It's never about pretty pictures; it's always about creating a message that engages audiences using the right touchpoints.",
                para2: "Does it project an emotional benefit?",
                para: "  Does it reflect a functional benefit? Those are the questions we ask when creating any above or below the line design. A brand's personality must always shine through."
            },
            {
                header: 'SOCIAL MEDIA',
                title: '<strong>WEBSITE & DIGITAL</strong> strategy and development.',
                para1: "The minute you are posting for the sake ofposting, you are risking losing the interestof audiences",
                para2: "From establishing social media channels",
                para: "to determining the right channel and right message for brands and creating and implementing engaging strategies that achieve the right objectives, our approach to social media focuses on moments of truth. Our planning is based on channel and audience consumption. Our approach is not about the number of followers, it’s focused on engagement and clearly defined KPIs"
            },
            {
                header: 'WEBSITE & DIGITAL',
                title: '<strong>VISUALIZATION</strong> videography & photography.',
                para1: "Websites should be so much more than digital brochures. They are the foundation of your digital presence and in today’s world they are the first place your audience looks to better understand you.",
                para2: "We design and develop websites",
                para: "based on user experience and create and implement digital media strategies that guide the right people to your website and intended messages. We create and manage eCommerce solutions and monitor user experience as well!"
            },
            {
                header: 'VISUALIZATION',
                title: '<strong>Social Media</strong> strategy, content, and design.',
                para1: "Websites should be so much more than digital brochures. They are the foundation of your digital presence and in today’s world they are the first place your audience looks to better understand you.",
                para2: "We design and develop websites",
                para: "From establishing social media channels to determining the right channel and message for brands, and creating and implementing engaging strategies that achieve the right objectives, our approach to social media focuses on moments of truth. Our planning is based on channel and audience consumption. Our approach is not about the number of followers; it's focused on engagement and clearly defined KPIs."
            },
            {
                header: 'PACKAGING',
                title: '<strong>PACKAGING</strong> design.',
                para1: "Keep it simple; that’s the rule of thumb",
                para2: "The most important thing to remember",
                para: "in packaging design is that you are trying to convince a consumer to stop, look and reach for your product. Cluttering the design will not do you any favours. "
            },
            {
                header: 'ACTIVATION',
                title: '<strong>ACTIVATION</strong> and event/campaign production.',
                para1: "Building a brand’s image and driving specific consumer action by bringing brands to life and then connecting and interacting with audiences on a personal level", 
                para2: "From activation to private and corporate events;",
                para: "understanding local cultures and aspirations guides us in delivering moments of truth where audiences are able to interact with brands and intended messages"
            }
        ];
        
    
    
    
    return(
        <>

            <section className='service-text-section'>
                <div className='container-fluid'>


                    <div className='header'>
                        <h2> DISCOVER OUR SERVICES MORE  </h2>
                        <h1> <span> </span> Services that Propel Your Business </h1>
                    </div>


                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='content'>
                                <img src={ServiceTextIMg} alt="service text img" />
                                
                            </div>
                        </div>







                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='content'>

                            {descriptions.map((description, index) => (

                                <>
                                <div className='info'>
                                    <h3  onClick={() => handleDescriptionClick(index)}
                                    className={activeDescriptions.includes(index) ? 'active' : ''}> <i class="las la-dot-circle"></i> {description.header}  <i className='toggle-description las la-angle-down'></i></h3>
                                </div>


                                <div className={`description ${activeDescriptions.includes(index) ? 'active' : ''}`} key={index}>
                                    <p className={`para-description ${activeDescriptions.includes(index) ? 'active' : ''}`}>
                                    <span dangerouslySetInnerHTML={{ __html: description.title }}></span><br></br>
                                    <h5> {description.para1} </h5>
                                    <h6> {description.para2} </h6>
                                    {description.para}
                                    </p>
                                </div>

                                </>
                                ))}


                            </div>
                        </div>


                    </div>


                </div>
            </section>
        
        </>
    )
}

export default ServiceText;