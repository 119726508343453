import './css/benefits.css';
import Scroll from './images/scroll.svg';

import BenefitsImg from  './images/benefits.webp';

function Benefits(){
    return(
        <>
            <div id="experience" className='benefits'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='benefit-text'>
                                <h2> RANGE OF WORK </h2>
                                <h1> <span></span> What Makes Aurora Marketing Group Stand Out?</h1>

                                <h3> At Aurora Marketing Group </h3>
                                <p>
                                    we go above and beyond to exceed our clients' expectations. Whether you're a startup, small or medium-sized enterprise, or a large corporation, we possess the expertise and experience to help you achieve your marketing goals.
                                </p>

                                <div className='benefits-content'>
                                    <div className='benefit'>
                                        <i class="las la-industry"></i>
                                        <h4> Industry Expertise and Innovation </h4>
                                    </div>

                                    <div className='benefit'>
                                        <i class="las la-tasks"></i>
                                        <h4> Proven Track Record and Results </h4>
                                    </div>

                                    <div className='benefit'>
                                        <i class="lar la-thumbs-up"></i>
                                        <h4>  Trusted Partner for Growth </h4>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='benefit-img'>
                                <img src={BenefitsImg} alt="benefit img" />

                                <h4> 
                                    <img src={Scroll} alt="scroll down" />
                                    <i class="las la-arrow-down"></i>
                                </h4>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Benefits;