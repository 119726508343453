import './css/landing.css';
import Scroll from './images/scroll.svg';
import Speaker from './images/sound_2058142.png';

import LandingVid from './images/landingvideo.mp4';

import Logoarrows from './images/logo-arrow.png';

import React, { useRef, useEffect } from 'react';



import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cube';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCube,EffectCoverflow,Navigation,Pagination} from 'swiper';



function Landing(){
    const videoRef = useRef(null);


    useEffect(() => {
        if (videoRef.current) {
        videoRef.current.playbackRate = 0.6; // Set the playback rate to 0.5x (half speed)
        }
    }, []);

    return(
        <>
            <section className='landing'>


                <div className='overlay'></div>


                <video ref={videoRef} muted autoPlay loop src={LandingVid} alt="landing Video" className='landing-img'></video>
                {/* <img src={Logoarrows} alt="Logo Arrows" className='logo-arrow' /> */}

                
                <div className='container-fluid'>






                <Swiper  grabCursor={true} slidesPerView={1}  
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay,EffectCoverflow,EffectCube,Navigation,Pagination]}
                    >

                    {/* <SwiperSlide>
                        <div className='landing-content'>
                            
                            <h3> Illuminate success with Aurora: your marketing partner </h3>
                            <h1> Unleash the Potential of Your Business   <img className='speaker' src={Speaker} alt="speaker" /></h1>

                            <h4> 
                                <img src={Scroll} alt="scroll down" />
                                <i class="las la-arrow-down"></i>
                            </h4>
                        </div>

                    </SwiperSlide> */}

                    <SwiperSlide>
                        <div className='landing-content'>
                            
                            <h3>  Illuminate success with Aurora: your marketing partner </h3>
                            <h1> Unleash Your Business's Potential   <img className='speaker' src={Speaker} alt="speaker" /></h1>

                            <h4> 
                                <img src={Scroll} alt="scroll down" />
                                <i class="las la-arrow-down"></i>
                            </h4>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='landing-content'>
                            
                            <h3>  Illuminate success with Aurora: your marketing partner </h3>
                            <h1> Elevate Your Brand's Influence       <img className='speaker' src={Speaker} alt="speaker" /></h1>

                            <h4> 
                                <img src={Scroll} alt="scroll down" />
                                <i class="las la-arrow-down"></i>
                            </h4>
                        </div>

                    </SwiperSlide>


                    
                    <SwiperSlide>
                        <div className='landing-content'>
                            
                            <h3>  Illuminate success with Aurora: your marketing partner </h3>
                            <h1> Boost Your Business's Performance   <img className='speaker' src={Speaker} alt="speaker" /></h1>

                            <h4> 
                                <img src={Scroll} alt="scroll down" />
                                <i class="las la-arrow-down"></i>
                            </h4>
                        </div>

                    </SwiperSlide>


                    <SwiperSlide>
                        <div className='landing-content'>
                            
                            <h3> Illuminate success with Aurora: your marketing partner  </h3>
                            <h1> Spark Your Business's Growth.    <img className='speaker' src={Speaker} alt="speaker" /></h1>

                            <h4> 
                                <img src={Scroll} alt="scroll down" />
                                <i class="las la-arrow-down"></i>
                            </h4>
                        </div>

                    </SwiperSlide>
                    

                    
                    
                </Swiper>

                </div>



            </section>
        </>
    )
}

export default Landing;