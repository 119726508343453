import './css/ad.css';

import AdBack from './images/landing.webp';
import LogoColored from './images/logocolored.png';

function Ad(props){
    return(
        <>
            <section className='ad'>
                <div className='overlay'></div>
                <img src={AdBack} alt="ad section" />

                <div className='container-fluid'>
                    <div className='ad-content'>
                        <img src={LogoColored} alt="logo Aurora"/>
                        <h1> {props.text} </h1>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ad;