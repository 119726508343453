import './css/aboutpage.css';

import LargeImg from './images/imagestouse/1.webp';
import MediumImg from './images/imagestouse/3.webp';
import SmallImg from './images/imagestouse/7.webp';


import { Link as RouterLink } from 'react-router-dom';
import AboutPageImg from './images/landing.webp';

import Services from './Services';

import ServiceText from './ServiceText';





import AboutImg from './images/big_0.webp';


function AboutPage(){
    return(
        <>


            <section className='about-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>

                                <div className='large-image'>
                                    <img src={LargeImg} alt='elateAboutImg' />
                                </div>

                                <div className='medium-image'>
                                    <img src={MediumImg} alt='elateAboutImg' />
                                </div>

                                <div className='small-image'>
                                    <img src={SmallImg} alt='elateAboutImg' />
                                    <div className='text'>
                                        <h1> 3K <span>+</span></h1>
                                        <h2>Creative Concepts </h2>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-content'>
                                <h1>  We are guardians, thinkers, activists and creative souls </h1>

                                <p> 
                                    We believe that another word for creativity is courage. Every day we
                                    depend on creativity, culture and technology to create emotional bonds
                                    between brands and consumers. Our approach to creative communication
                                    strategy is built on experience, intelligence, dialogue and most of all courage.
                                </p>

                                {/* <div className='benefits'>
                                    <img src={AboutImg} alt="AboutImg" />

                                    <ul>
                                        <li>
                                            <i class="las la-check-circle"></i>
                                            BRANDING

                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            ADVERTISING

                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            SOCIAL MEDIA


                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            WEBSITE & DIGITAL


                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            ACTIVATION
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            VISUALIZATION
                                        </li>

                                        <li>
                                            <i class="las la-check-circle"></i>
                                            PACKAGING
                                        </li>
                                    </ul>
                                </div>

                                <a target="_blank" href="https://wa.me/+9660543708205">
                                    Know More
                                    <i class="las la-arrow-right"></i>
                                </a> */}
                            </div>
                            
                        </div>

                    </div>
                </div>
            </section>




        </>
    )
}

export default AboutPage;