
import AboutPageImg from './images/servicetext.webp';

import Services from './Services';

import ServiceText from './ServiceText';

import { Link as RouterLink } from 'react-router-dom';




function AboutPage(){
    return(
        <>

                <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={AboutPageImg}  alt='aboutpageimg'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> Our Solutions </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    Home
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/our-solutions'>
                                    our solutions
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>

            <ServiceText />

            <Services />



        </>
    )
}

export default AboutPage;