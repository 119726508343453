import React, { useState } from 'react';
import axios from '../config/index'; // Make sure this import is correct
import './css/contact.css';
import CareerImg from './images/career.webp';

function Proposal() {
// Define the initial form state
const initialFormData = {
    Ans1: '',
    Ans2: '',
    Ans3: '',
    Ans4: '',
    Ans5: '',
    Ans6: '',
    Ans7: '',
    Ans8: '',
    Ans9: '',
    Ans10: '',
    Ans11: '',
    Ans12: '',
    Ans13: '',
    Ans14: '',
    Ans15: '',
    Ans16: '',
    Ans17: '',
    Ans18: '',
    Ans19: '',
    Ans20: '',
    Ans21: '',
    Ans22: '',
    Ans23: '',
    Ans24: '',
    Ans25: '',
    Ans26: '',
    Ans27: '',
    Ans28: '',
    Ans29: '',
    Ans30: '',
};

const [formData, setFormData] = useState(initialFormData);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
    ...formData,
    [name]: value,
    });
};

const resetForm = () => {
    setFormData(initialFormData);
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    const response = await axios.post('/send-proposal', formData, {
        headers: {
        'Content-Type': 'application/json',
        },
    });

    if (response.status === 200) {
        // Successful submission, you can display a success message or redirect the user
        alert('Message sent successfully!');
        // Reset the form
        resetForm();
    } else {
        // Handle server error or validation errors
        alert('Failed to send message. Please try again later.');
    }
    } catch (error) {
    // Handle network error or other errors
    console.error('Error:', error);
    alert('An error occurred while sending the message.');
    }
};

return (
    <section className="contact">
    <div className="container-fluid">
        <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="contact-images">
            <div className="back-lay"></div>
            <img loading="lazy" src={CareerImg} alt="ContactUs" />
            </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="contact-form">
            <h1>
            Grow Your Business With <span> Aurora</span>
            </h1>

            <p>
            Ready to grow your business with Aurora? Let's do it!
            </p>
            <form onSubmit={handleSubmit}>
                <div className="inputs special">
                <div className="input">
                    <label> Company\Organization Name? </label>
                    <input
                    type="text"
                    name="Ans1"
                    value={formData.Ans1}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> Contact Person's Name? </label>
                    <input
                    type="text"
                    name="Ans2"
                    value={formData.Ans2}
                    onChange={handleChange}
                    required
                    />
                </div>
                </div>
                <div className="inputs special">
                <div className="input">
                    <label> Contact Person's Position? </label>
                    <input
                    type="text"
                    name="Ans3"
                    value={formData.Ans3}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> Contact Person's Phone Number? </label>
                    <input
                    type="tel"
                    name="Ans4"
                    value={formData.Ans4}
                    onChange={handleChange}
                    required
                    />
                </div>
                </div>
                <div className="inputs special">
                <div className="input">
                    <label> Contact Person's Email Address? </label>
                    <input
                    type="email"
                    name="Ans5"
                    value={formData.Ans5}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> Contact Person's Address? </label>
                    <input
                    type="text"
                    name="Ans6"
                    value={formData.Ans6}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>

                <div className="inputs special">
                <div className="input">
                    <label> What is the nature of the business? </label>
                    <input
                    type="text"
                    name="Ans7"
                    value={formData.Ans7}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> Do you offer products or services? </label>
                    <input
                    type="text"
                    name="Ans8"
                    value={formData.Ans8}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>



                <div className="inputs special">
                <div className="input">
                    <label> What are the main products or services offered? </label>
                    <input
                    type="text"
                    name="Ans9"
                    value={formData.Ans9}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> What are company's short-term objectives? </label>
                    <input
                    type="text"
                    name="Ans10"
                    value={formData.Ans10}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>


                

                <div className="inputs special">
                <div className="input">
                    <label> What are company's long-term objectives? </label>
                    <input
                    type="text"
                    name="Ans11"
                    value={formData.Ans11}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> What are the current marketing strategies being implemented? </label>
                    <input
                    type="text"
                    name="Ans12"
                    value={formData.Ans12}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>


                <div className="inputs special">
                <div className="input">
                    <label> Who is the primary target audience or customer demographic? </label>
                    <input
                    type="text"
                    name="Ans13"
                    value={formData.Ans13}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> What are their characteristics? (age, gender, location, interests, etc..) </label>
                    <input
                    type="text"
                    name="Ans14"
                    value={formData.Ans14}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>




                <div className="inputs special">
                <div className="input">

                    <label> What are their needs & pain points? </label>
                    <input
                    type="text"
                    name="Ans15"
                    value={formData.Ans15}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> Who are the main competitors in the industry? </label>
                    <input
                    type="text"
                    name="Ans16"
                    value={formData.Ans16}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>



                
                <div className="inputs special">
                <div className="input">
                    <label> What sets the client apart from their competitors? </label>
                    <input
                    type="text"
                    name="Ans17"
                    value={formData.Ans17}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> What are the strengths & weaknesses of the client's competitors? </label>
                    <input
                    type="text"
                    name="Ans18"
                    value={formData.Ans18}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>



                <div className="inputs special">
                <div className="input">
                    <label> Does the client have an established brand identity? </label>
                    <input
                    type="text"
                    name="Ans19"
                    value={formData.Ans19}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> What are the brand values and key messaging? </label>
                    <input
                    type="text"
                    name="Ans20"
                    value={formData.Ans20}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>



                
                <div className="inputs special">
                <div className="input">
                    <label> How does the client want to be perceived by the target audience? </label>
                    <input
                    type="text"
                    name="Ans21"
                    value={formData.Ans21}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> What are the client's specific marketing goals & objectives? </label>
                    <input
                    type="text"
                    name="Ans22"
                    value={formData.Ans22}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>



                <div className="inputs special">
                <div className="input">
                    <label> Are there any specific metrics or Key Performance Indicators (KPIs) to track? </label>
                    <input
                    type="text"
                    name="Ans23"
                    value={formData.Ans23}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> Are there any specific deadlines or time constrains to consider? </label>
                    <input
                    type="text"
                    name="Ans24"
                    value={formData.Ans24}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>


                
                <div className="inputs special">
                <div className="input">
                    <label> What marketing channels are currently being utilized? (e.g., website, social media, email marketing, etc.) </label>
                    <input
                    type="text"
                    name="Ans25"
                    value={formData.Ans25}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label> How effective are these channels in reaching the target audience? </label>
                    <input
                    type="text"
                    name="Ans26"
                    value={formData.Ans26}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>




                
                <div className="inputs special">
                <div className="input">
                    <label> What marketing strategies or campaigns have been implemented in the past? </label>
                    <input
                    type="text"
                    name="Ans27"
                    value={formData.Ans27}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div className="input">
                    <label>What were the results and lessons learned from previous marketing efforts?</label>
                    <input
                    type="text"
                    name="Ans28"
                    value={formData.Ans28}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>



                
                
                <div className="inputs special">
                <div className="input">
                    <label>Is there any other relevant information or specific requirements the client wants to mention?</label>
                    <input
                    type="text"
                    name="Ans29"
                    value={formData.Ans29}
                    onChange={handleChange}
                    required
                    />
                </div>

                </div>







                <button aria-label="Send Message Form" id="submit-button" type="submit">
                Send Proposal <i className="las la-arrow-right"></i>
                </button>
            </form>
            </div>
        </div>
        </div>
    </div>
    </section>
);
}

export default Proposal;
