
import Contact from './Contact';
import AboutPageImg from './images/pexels-pixabay-416405.webp';



import { Link as RouterLink } from 'react-router-dom';





function ContactPage(){
    return(
        <>

                <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={AboutPageImg}  alt='aboutpageimg'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> Contact Us </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    Home
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/contact-us'>
                                    contact us
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>

            <Contact />



        </>
    )
}

export default ContactPage;