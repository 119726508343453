
import Proposal from './Proposal';
import CareersPageImg from './images/small_1.webp';



import { Link as RouterLink } from 'react-router-dom';




function GetProposal(){
    return(
        <>

                <section className='page-header'>
                    <div className='overlay'></div>
                    <img  loading='lazy' src={CareersPageImg}  alt='aboutpageimg'/>
                    <div className='page-header-content'>
                        <div className='text'>
                            <h1> Get A Proposal  </h1>
                            <div className='links'>
                                <RouterLink to="/">
                                    Home
                                </RouterLink>
                                <i class="las la-arrow-right"></i>
                                <RouterLink to='/get-proposal'>
                                    Get A Proposal
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </section>

            <Proposal />



        </>
    )
}

export default GetProposal;