import './css/footer.css';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

function Footer(){
    return(
        <>
            <footer>
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img  loading='lazy' src={Logo} alt="logo" />
                                </div>

                                <p> Our team of skilled professionals is dedicated to staying ahead of industry trends and leveraging innovative approaches to maximize your marketing success. With a proven track record of delivering results for businesses across various industries, we are the trusted partner you need to ignite growth and achieve enduring success. </p>

                                <h2> We Are Avaiable </h2>
                                <h3> Sun-Thu : 08:00 Am to 6:00 Pm </h3>

                                <div className='social'>
                                    <a aria-label='facebook aurora link' target="_blank" href="https://www.facebook.com/profile.php?id=61551042727391&mibextid=2JQ9oc">
                                        <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a aria-label='instagram aurora link' target="_blank" href="https://instagram.com/aurora.marketing.group?igshid=OGQ5ZDc2ODk2ZA==">
                                        <i class="lab la-instagram"></i>
                                    </a>

                                    <a aria-label='twitter aurora link' target="_blank" href="https://x.com/auroraksa23?s=11&t=Z3dWoTxy2YbeXIZpiESFWg">
                                        <i class="lab la-twitter"></i>
                                    </a>

                                    <a aria-label='linked-in aurora link' target="_blank" href="https://www.linkedin.com/company/aurora-marketing-group/">
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>




                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> FAST LINKS </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                <ul>
                                <li>
                                    <RouterLink aria-label='home page link' to="/">
                                        HOME 
                                    </RouterLink>
                                </li>

                                <li>
                                    <a aria-label='about us link'  href="/#about-us">
                                        ABOUT US
                                    </a>
                                </li>
                                


                                <li>
                                    <a aria-label='our solutions link' href="/#our-solutions">
                                        SERVICES
                                    </a>
                                </li>

                                
                                <li>
                                    <a aria-label='contact us link' href="/#experience">
                                        EXPERIENCE
                                    </a>
                                </li>


                                
                                <li>
                                    <RouterLink aria-label='contact us link' to="/careers">
                                        CAREERS
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink aria-label='contact us link' to="/get-proposal">
                                        GET A PROPOSAL
                                    </RouterLink>
                                </li>



                                <li>
                                    <RouterLink aria-label='contact us link' to="/contact-us">
                                        CONTACT US
                                    </RouterLink>
                                </li>

                                

                                </ul>
                            </div>
                        </div>


                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> FAST LINKS </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                    <div className='contact-info'>

                                    <div className='info'>
                                        <h4> PHONE NUMBER </h4>
                                        <a aria-label='phonenumber aurora' href="tel:+9660543708205"> <i class="las la-phone"></i> +9660543708205 </a>
                                    </div>

                                    <div className='info'>
                                        <h4> EMAIL ADDRESS </h4>
                                        <a aria-label='Email Address aurora' href="mailto:info@aurora-ksa.com"> <i class="las la-envelope"></i> info@aurora-ksa.com  </a>
                                    </div>

                                    <div className='info'>
                                        <h4 aria-label='office location'> OFFICE LOCATION </h4>
                                        <a href="https://maps.app.goo.gl/Z2taj2ULwqfrYfRZ6?g_st=ic" target='_blank'> <i class="las la-map-marker"></i> Jeddah  </a>
                                    </div>
                                </div>


                            </div>

                            
                        </div>



                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1> USE WHATSAPP </h1>
                                <div className='line'>
                                    <span></span>
                                    <span></span>
                                </div>

                                <p>
                                    At Aurora Marketing Group, we go above and beyond to exceed our clients' expectations. Whether you're a startup, small or medium-sized enterprise, or a large corporation, we possess the expertise and experience to help you achieve your marketing goals.
                                </p>

                                <a aria-label="whatsapp link" target="_blank" href="https://wa.me/+9660543708205"> 
                                    SEND MESSAGE NOW <i class="las la-arrow-right"></i>
                                </a>


                            </div>

                        </div>







                    </div>
                </div>

                <div className='copy-right'>
                    <h1>  &copy; 2023   <span> aurora-ksa </span> All Rights Reserved. </h1>
                </div>
            </footer>
        </>
    )
}

export default Footer;